.container-wuc {
	max-width: 800px;
	margin: 0 auto;
	text-align: center;
  }
  
  .background-image {
	width: 100%;
	height: 40vh;
	background-image: url("https://http.cat/200.jpg");
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
  }
  
  .content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: -5vh;
  }
  
  .heading {
	font-size: 3rem;
	margin-top: 3rem;
	color: #000000;
  }
  
  .message {
	font-size: 1.5rem;
	margin-top: 2rem;
	color: #000000;
  }
  