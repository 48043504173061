@import url("https://dev-cats.github.io/code-snippets/JetBrainsMono.css");

.App {
	color: black;
	border: black 1px;
}

.navbar {
	margin-bottom: 20px !important;

	color: white;
	background-color: black;
	padding: 0.5em;
	text-align: right;
}

.pagetitle {
	text-align: left;
	margin: 0;
	padding-left: 64px;
}

.active {
	background-color: white;
	color: black;
}

button {
	background-color: black;
	color: white;
	font-family: "JetBrains Mono";
	padding: 5px;
	text-decoration: none;
	color: inherit;
}

button:hover {
	background-color: white;
	color: black;
	cursor: pointer;
}